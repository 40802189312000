// Imports
// ------
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '@theme';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';

// Styles
// ------
import '@css/global.css';

// Component
// ------
const Layout = ({ location, children }) => {
	return (
		<TransitionProvider
			location={location}
			mode='successive'
			enter={{
				opacity: 0,
				config: {
					duration: 500,
				},
				onRest: () => {
					// console.log('Hello, World!');
				},
			}}
			usual={{
				opacity: 1,
			}}
			leave={{
				opacity: 0,
				config: {
					duration: 500,
				},
			}}>
			<ThemeProvider theme={theme}>
				<TransitionViews>
					{children}
				</TransitionViews>
			</ThemeProvider>
			<GlobalStyle theme={theme} />
		</TransitionProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
